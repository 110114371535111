import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCounter(prevCounter => prevCounter + 10);
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="App">
            <header className="App-header">
                <h1>Monies: ${counter}</h1>
            </header>
        </div>
    );
}

export default App;
